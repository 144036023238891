import React from 'react';
import { Box, Card, CardActionArea, CardMedia, Typography } from "@material-ui/core";

import sliderCardStyles from "./styles";
import SliderCardContent from "./content";

export interface SlideCardProps {
    title: string;
    location: string;
    price?: number;
    priceVariation?: boolean;
    image?: string;
    imageTitle?: string;
    date: Date;
}

export default function SliderCard(props: SlideCardProps) {
    const classes = sliderCardStyles();

    return (
        <Card className={classes.root} elevation={0 }>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={props.image}
                    title="Contemplative Reptile">
                    <Box display="flex" className={classes.dateWrapper}>
                        <Typography className={classes.bold}>{new Intl.DateTimeFormat("en-GB", {
                            day: "2-digit"
                        }).format(props.date)}</Typography>
                        <Typography className={classes.bold}>{new Intl.DateTimeFormat("en-GB", {
                            month: "short",
                        }).format(props.date)}</Typography>
                    </Box>
                </CardMedia>
               <SliderCardContent {...props} />
            </CardActionArea>
        </Card>
    );
}
import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

import {HomePage} from './pages/home';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            margin: '0 auto',
            maxWidth: 1440,
            fontSize: '.875rem'
        }
    }),
);


function App() {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <HomePage/>
        </div>
    );
}

export default App;

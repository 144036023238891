import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const autoCompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        popPaper: {
            margin: 0,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none'
        },
        listBox: {
            padding: 0,
        },
        listBoxWithLocation: {
            "&>li:first-of-type": {
                borderBottom: `1px solid ${theme.palette.divider}`
            },
            "& li": {
                lineHeight: 1.66667
            }
        },
        option: {
            fontWeight: 'bolder',
            fontSize: '.75rem',
            padding: theme.spacing(1, 2)
        },
        optionWithParent: {
            fontWeight: 'normal',
            padding: theme.spacing(0,2)
        },
        tag: {
            margin: theme.spacing(0, 0.1875)
        },
        icon: {
            color: theme.palette.primary.main
        }
    })
);

export const searchPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: `${theme.spacing(4)}px auto`,
            flexGrow: 1,
            justifyContent: 'center',
            padding: theme.spacing(1, 4),
            '@media (min-width: 1024px)': {
                maxWidth: '80%'
            },
            '@media (min-width: 1366px)': {
                maxWidth: '66%'
            }
        },
        locationField: {
            display: 'inline-block',
            maxWidth: '50%',
            width: '100%'
        },
        dateField: {
            display: 'inline-block',
            maxWidth: '50%',
            width: '100%'
        },
        expand: {
            transition: 'max-width 1s ease-in-out',
            flexBasis: '100%',
            maxWidth: '100%'
        },
        collapse: {
            transition: 'all 1s ease-in-out',
            flexBasis: 0,
            maxWidth: 0,
        },
        gridContainer: {
            borderRadius: theme.shape.borderRadius
        },
        gridItem: {
            overflow: 'hidden'
        },
        searchButtonContainer: {
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            backgroundColor: theme.palette.primary.main,
        },
        iconButton: {
            height: '100%',
            width: '100%',
            color: theme.palette.background.default,
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        },
        button: {
            flex: 1,
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            height: '100%'
        },
        noMargin: {
            margin: 0
        }
    }),
);


import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const sliderCardStyles = makeStyles((theme: Theme) => {

    const lineHeightString = theme.typography.subtitle2 && theme.typography.subtitle2.lineHeight ?
        theme.typography.subtitle2.lineHeight.toString() : '1';
    const lineHeight = parseFloat(lineHeightString);

    return createStyles({
        root: {
            margin: theme.spacing(0, 0.2),
            maxWidth: 340,
        },
        media: {
            height: 290,
        },
        dateWrapper: {
            flexDirection: 'column',
            border: `1px solid ${theme.palette.background.default}`,
            display: 'inline-block',
            padding: theme.spacing(1, 2),
            margin: theme.spacing(3),
            color: theme.palette.background.default,
            textTransform: 'uppercase',
            textAlign: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        eventTitle: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            height: `${lineHeight * 2}em`,
            marginBottom: theme.spacing(2)
        },
        locationPriceWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        locationWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        locationIcon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(0.5),
            height: '.75em'
        },
        location: {
            color: theme.palette.text.secondary
        },
        priceWrapper: {
            color: theme.palette.primary.main,
        },
        pricePrefix: {
            marginRight: theme.spacing(0.5)
        },
        bold: {
            fontWeight: 'bolder',
        }
    });
});

export default sliderCardStyles;
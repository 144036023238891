import React, {useCallback, useEffect, useMemo, useState} from "react";
import querystring, {ParsedUrlQueryInput} from "querystring";
import {throttle} from 'lodash';
import {TextField, InputAdornment, Grid, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {LocationOn as LocationIcon} from "@material-ui/icons";

import * as Constants from "../../constants";
import {AutoCompleteFieldProps} from "../../interfaces";
import {autoCompleteStyles} from "../../styles";
import locationFieldStyles from "./styles";
import classNames from "classnames";


interface LocationFieldProps extends AutoCompleteFieldProps {
    setOpenModalFn: (open: boolean) => void,
    coords?: object
}

interface QueryParams extends ParsedUrlQueryInput{
    query: string;
    key: string|undefined;
    maxResults?: number;
    includeEntityTypes?: string;
    culture?: string;
    userRegion?: string;
    countryFilter?: string;
}

interface PlaceType {
    __type: string;
    address: {
        countryRegion: string;
        locality: string;
        adminDistrict: string;
        countryRegionIso2: string;
        postalCode: string;
        addressLine: string;
        formattedAddress: string
    };
    name?: string
}

export function LocationField(props: LocationFieldProps) {
    const autoCompleteClasses = autoCompleteStyles();
    const classes = locationFieldStyles();
    const onLocationFocus = useCallback(() => props.setCurrentFocusedFn(Constants.SEARCH_FIELDS.LocationField), [props]);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<PlaceType[]>([]);

    const language = 'de-DE'; //TODO Global
    const countryIso = 'DE'; //TODO Global

    const queryParams:QueryParams = {
        query: inputValue,
        key: Constants.BING_MAP.MAP_KEY,
        maxResults: Constants.MAX_LOCATION_AUTOSUGGEST_RESULTS,
        includeEntityTypes: 'Place',
        culture: language,
        userRegion: countryIso,
        countryFilter: countryIso
    };

    const queryString = querystring.stringify(queryParams);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const fetchLocations = useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                (async () => {
                    let results = [];
                    const response = await fetch(Constants.BING_MAP.AUTO_SUGGEST_URL + '?' + queryString);
                    const jsonResponse = await response.json();

                    const resourceSet = jsonResponse.resourceSets ? jsonResponse.resourceSets.pop() : undefined;

                    if (resourceSet) {
                        const resources = resourceSet.resources ? resourceSet.resources.pop() : undefined;

                        if (resources && resources.value) {
                            results = resources.value;
                        }
                    }

                    callback(results);

                 })();
            }, 200),
        [ queryString ],
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetchLocations({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                setOptions(results || []);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetchLocations]);

    return (
        <Autocomplete
            clearOnEscape
            disableClearable
            freeSolo
            classes={{
                inputRoot: classes.whereInputRoot,
                paper: autoCompleteClasses.popPaper,
                option: classNames({
                    [autoCompleteClasses.option]: true,
                    [classes.option]: true
                }),
                tag: autoCompleteClasses.tag,
                listbox: classNames({
                    [autoCompleteClasses.listBox]: true
                })
            }}
            size="medium"
            getOptionLabel={option => {
                if (typeof option === 'string') {
                    return option;
                }

                if (typeof option === 'object') {
                    return option.name ? option.name : option.address.locality;
                }

                return '';
            }}
            filterOptions={x => x.filter(option => option.name === undefined)}
            options={options}
            autoComplete
            renderInput={(params) => {
                params.InputProps = {
                    ...params.InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <LocationIcon/>
                        </InputAdornment>
                    )
                };

                return (
                    <TextField
                        {...params}
                        label="Wo?"
                        variant="outlined"
                        type="text"
                        margin="none"
                        onChange={handleChange}
                    />
                );
            }}
            renderOption={option => {
                return (
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems:  'center'}}>
                        <LocationIcon fontVariant="small" />
                        <div style={{marginLeft: 8}}>{option.name ? option.name : option.address.locality}</div>
                    </div>
                );
            }}
            onFocus={onLocationFocus}
            onBlur={props.onBlur}
        />
    );
}
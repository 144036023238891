import React, {useCallback} from "react";
import {geolocated} from "react-geolocated";
import classNames from "classnames";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {LocationOn as LocationIcon} from "@material-ui/icons";
import {ClassNameMap} from "@material-ui/styles/withStyles";

import * as Constants from "../../constants";
import {AutoCompleteFieldProps, AutoCompleteItem} from "../../interfaces";
import {autoCompleteStyles} from "../../styles";
import searchFieldStyles from "./styles";

interface SearchFieldProps extends AutoCompleteFieldProps {
    coords?: object
}

const renderSearchOption = (option: AutoCompleteItem, classes: ClassNameMap<string>) => {
    const icon = option.icon ? option.icon : null;
    const optionCmp = option.hasParent ? (
        <div className={classes.optionWithParent}>
            {option.title}
        </div>
    ) : <div style={{marginLeft: 8}}>{option.title}</div>;

    return (
        <div
            className={classNames({
                    [classes.withDivider]: option.system
            })}
            style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems:  'center'}}>
            {icon}
            {optionCmp}
        </div>
    );
};

export const withGeolocation = geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(SearchField);

export function SearchField(props: SearchFieldProps) {
    const autoCompleteClasses = autoCompleteStyles();
    const classes = searchFieldStyles();
    const onSearchFocus = useCallback(() => props.setCurrentFocusedFn(Constants.SEARCH_FIELDS.SearchField), [props]);

    if (props.coords) {
        props.autocompleteList.unshift({
            group: '',
            title: 'Alles in meiner Nähe',
            system: true,
            icon: <LocationIcon fontSize="small" className={autoCompleteClasses.icon} />
        });
    }

    return (
        <Autocomplete
            classes={{
                paper: autoCompleteClasses.popPaper,
                inputRoot: classNames({
                    [classes.searchInputRoot]: true,
                    [classes.searchInputRootMobile]: !props.isMinSmBreakpoint
                }),
                option: autoCompleteClasses.option,
                tag: autoCompleteClasses.tag,

                listbox: classNames({
                    [autoCompleteClasses.listBox]: true,
                    [autoCompleteClasses.listBoxWithLocation]: props.coords,
                })
            }}
            multiple
            freeSolo
            size="medium"
            options={props.autocompleteList}
            getOptionLabel={(option) => option.title }
            renderOption={(option) => renderSearchOption(option, autoCompleteClasses)}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Was?"
                    margin="none"
                    variant="outlined"
                    type="text"
                placeholder="Suchbegriff"
                />
            )}
            onFocus={onSearchFocus}
            onBlur={props.onBlur}
        />
    );
}
export const SEARCH_FIELDS = {
    SearchField: {
        name: 'search'
    },
    LocationField: {
        name: 'location'
    },
    DateField: {
        name: 'date'
    }
};

export const BING_MAP = {
    AUTO_SUGGEST_URL: 'http://dev.virtualearth.net/REST/v1/Autosuggest',
    MAP_KEY: 'AiqfoE1WcbFihIPO4x7aVNVqwBmAvMmBKfihuKu6sBWD3qQhYnj21G1skL4YhXzV'
};

export const MAX_LOCATION_AUTOSUGGEST_RESULTS = 10;
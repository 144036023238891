import React, {useCallback} from "react";
import {InputAdornment, TextField} from "@material-ui/core";

import * as Constants from "../../constants";
import {SearchPanelFieldProps} from "../../interfaces";
import dateFieldStyles from "./styles";
import {Event as EventIcon} from "@material-ui/icons";

export function DateField(props: SearchPanelFieldProps) {
    const classes = dateFieldStyles();
    const onDateFocus = useCallback(() => props.setCurrentFocusedFn(Constants.SEARCH_FIELDS.DateField), [props]);

    return (
        <TextField
            fullWidth
            type="text"
            label="Wann?"
            variant="outlined"
            size="medium"
            margin="none"
            InputProps={{
                className: classes.whenField,
                endAdornment: (
                    <InputAdornment position="end">
                        <EventIcon />
                    </InputAdornment>
                ),
            }}
            onFocus={onDateFocus}
            onBlur={props.onBlur}
        />
    );
}
import React from 'react';
import {Divider, Typography} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface SliderHeaderProps { title: string; }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(4, 4)
        },
        divider: {
        },
        sliderTitle: {
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(0, 3, 0 , 4),
            top: `${theme.typography.subtitle2.lineHeight}em`,
            left: 0,
            fontWeight: 'bold',
            textTransform: 'uppercase'
        }
    })
);

export default function SliderHeader(props: SliderHeaderProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Divider className={classes.divider}/>
            <Typography variant="subtitle2" className={classes.sliderTitle}>{props.title}</Typography>
        </div>
    );
}
import React from "react";
import classNames from "classnames";

import { Box, CardContent, Typography } from "@material-ui/core";
import { default as Ellipses } from "react-dotdotdot";
import { LocationOn as LocationIcon } from "@material-ui/icons";

import { SlideCardProps } from "./card";
import sliderCardStyles from "./styles";

export default function SliderCardContent(props: SlideCardProps) {
    const classes = sliderCardStyles();

    const pricePrefix = props.priceVariation ? (
        <Typography className={classNames({
            [classes.bold]: true,
            [classes.pricePrefix]: true})}>
            ab
        </Typography>
    ) : null;

    const priceCmp = props.price ? (
        <Typography className={classNames({[classes.bold]: true})}>
            {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR"
            }).format(props.price)}
        </Typography>
    ) : null;

    return (
        <CardContent>
            <Typography variant="subtitle1" component="h2" className={classes.eventTitle}>
                <Ellipses clamp={2}>
                    {props.title}
                </Ellipses>
            </Typography>
            <Box display="flex" className={classes.locationPriceWrapper}>
                <Box display="flex" className={classes.locationWrapper}>
                    <LocationIcon className={classes.locationIcon}/>
                    <Typography className={classes.location}>{props.location}</Typography>
                </Box>
                <Box display="flex" className={classes.priceWrapper}>
                    {pricePrefix}
                    {priceCmp}
                </Box>
            </Box>
        </CardContent>
    );
}
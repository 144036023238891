import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-open-sans';

const theme = createMuiTheme({
    // shape: {
    //     borderRadius: 0
    // },
    typography: {
        fontFamily: [
            '"Open Sans"',
            '"Helvetica Neue"',
            '"Helvetica"',
            '"Arial"',
            '"Lucida Grande"',
            'sans-serif',
        ].join(','),
        body1: {
            fontSize: 14
        }
    },
    palette: {
        primary: {
            main: '#d20a11',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: '#850200',
        },
        background: {
            default: '#fff',
        },
    },
    overrides: {

    }
});

export default theme;
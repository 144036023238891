import * as React from "react";

import NavBar from "../navbar";
import SearchPanel from "../search";
import EventSlider from "../slider";

export const Home = () => <div>
    <NavBar />
    <SearchPanel />
    <EventSlider/>
</div>;
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const searchFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchInputRoot: {
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
            "& .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 0
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 1
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 2
            },
            "@media (max-width: 600px)": {
                borderBottomLeftRadius: 0
            }
        },
        searchInputRootMobile: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderBottomWidth: 0,
            },
        }
    })
);

export default searchFieldStyles;
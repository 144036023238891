import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import SliderCard from "./card";
import SliderHeader from "./divider";

export default function EventSlider() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5,
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
        }
    };

    const events = [
        {
            id: '2323445',
            title: 'Niekamp Theater Company: Pettersson und Findus',
            location: 'Bieleld',
            date: new Date('2020-08-20T20:00:00.000Z'),
            image: '/static/images/contemplative-reptile.jpg',
        },
        {
            id: '2323446',
            title: 'Matthias Jung',
            location: 'Bieleld',
            date: new Date('2020-08-21T20:00:00.000Z'),
            image: '/static/images/paella.jpg',
            price: 25.90,
            priceVariation: true
        }
    ];

    return (
        <React.Fragment>
            <SliderHeader title="Veranstaltung in Bielefeld"/>
            <Carousel
                centerMode={true}
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={10000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType="desktop"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {events.map((event) => {
                    return (
                        <SliderCard
                            key={event.id}
                            title={event.title}
                            location={event.location}
                            date={event.date}
                            image={event.image}
                            imageTitle={event.title}
                            price={event.price}
                            priceVariation={event.priceVariation}
                        />
                    );
                })}
            </Carousel>
        </React.Fragment>
    );
}
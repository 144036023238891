import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const dateFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        whenField: {
            flex: 1,
            borderRadius: 0,
            borderLeft: 0,

            "& .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 0
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 1
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 2
            },
        }
    })
);

export default dateFieldStyles;
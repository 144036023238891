import React, {useCallback, useState} from "react";
import classNames from "classnames";
import {useSpring, animated} from "react-spring";
import {Box, Button, Grid, IconButton, useMediaQuery} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import * as Constants from "./constants";
import * as Interfaces from "./interfaces";
import {withGeolocation as SearchField} from "./fields/search/search";
import {LocationField} from "./fields/location/location";
import {DateField} from "./fields/date/date";
import {searchPanelStyles} from "./styles";

export default function SearchPanel() {
    const [currentFocusedField, setCurrentFocusedField] = useState<Interfaces.FocusableField | undefined>(undefined);
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const [openDateModal, setOpenDateModal] = useState(false);

    const onBlur = useCallback(() => {
        setCurrentFocusedField(undefined);
        setOpenLocationModal(false);
        setOpenDateModal(false);
    }, []);

    const classes = searchPanelStyles();
    const isMinSmBreakpoint = useMediaQuery('(min-width: 600px)');

    const categoryList:Interfaces.AutoCompleteItem[] = [
        {
            group: '',
            title: 'Alle Veranstalltungen'
        },
        {
            group: 'Humor',
            title: 'Comedy',
        },
        {
            group: 'Humor',
            title: 'Kabarett',
            hasParent: true
        },
        {
            group: 'Konzert',
            title: 'Festivals',
        },
    ];

    const locationList = [
        {
            group: '',
            title: 'Bielefeld'
        },
        {
            group: '',
            title: 'Brakel'
        }
    ];

    const locationAnimationProps = useSpring({
        from: {
            maxWidth:'50%'
        },
        to: {
            maxWidth: currentFocusedField === Constants.SEARCH_FIELDS.LocationField ? '100%' : currentFocusedField === Constants.SEARCH_FIELDS.DateField ? '0%' : '50%',
            opacity: currentFocusedField === Constants.SEARCH_FIELDS.DateField ? 0 : 1
        },
        delay: 200,
        onRest: () => setOpenLocationModal(currentFocusedField === Constants.SEARCH_FIELDS.LocationField)
    });
    const dateAnimationProps = useSpring({
        from: {
            maxWidth:'50%'
        },
        to: {
            maxWidth: currentFocusedField === Constants.SEARCH_FIELDS.DateField ? '100%' : currentFocusedField === Constants.SEARCH_FIELDS.LocationField ? '0%' : '50%',
            opacity: currentFocusedField === Constants.SEARCH_FIELDS.LocationField ? 0 : 1
        },
        delay: 200,
        onRest: () => setOpenDateModal(currentFocusedField === Constants.SEARCH_FIELDS.DateField)
    });

    return (
        <Box display="flex" className={classes.root}>
            <Grid container justify="center" className={classes.gridContainer}>
                <Grid item xs={10} container>
                    <Grid item xs={12} sm={6}>
                        <SearchField
                            openModal
                            isMinSmBreakpoint={isMinSmBreakpoint}
                            autocompleteList={categoryList}
                            currentFocusedField={currentFocusedField}
                            setCurrentFocusedFn={setCurrentFocusedField}
                            onBlur={onBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <animated.div
                            className={classes.locationField}
                            style={locationAnimationProps}
                        >
                            <LocationField
                                openModal={openLocationModal}
                                setOpenModalFn={setOpenLocationModal}
                                isMinSmBreakpoint={isMinSmBreakpoint}
                                autocompleteList={locationList}
                                currentFocusedField={currentFocusedField}
                                setCurrentFocusedFn={setCurrentFocusedField}
                                onBlur={onBlur}
                            />
                        </animated.div>
                        <animated.div
                            className={classes.dateField}
                            style={dateAnimationProps}
                        >
                            <DateField
                                isMinSmBreakpoint={isMinSmBreakpoint}
                                currentFocusedField={currentFocusedField}
                                setCurrentFocusedFn={setCurrentFocusedField}
                                onBlur={onBlur}
                            />
                        </animated.div>

                    </Grid>
                </Grid>
                <Grid item xs={2} className={classes.searchButtonContainer}>
                    {!isMinSmBreakpoint ? (
                        <IconButton aria-label="Suchen" className={classes.iconButton}>
                            <SearchIcon/>
                        </IconButton>
                    ) : (
                        <Button
                            fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classNames({
                                [classes.button]: true,
                                [classes.noMargin]: isMinSmBreakpoint
                            })}
                            startIcon={<SearchIcon/>}
                        >
                            Suchen
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
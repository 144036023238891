import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const locationFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        option: {
            fontWeight: 'normal'
        },
        whereInputRoot: {
            borderRadius: 0,
            "& .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 0,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 1
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRightWidth: 2
            },
            "@media (max-width: 600px)": {
                borderBottomLeftRadius: theme.shape.borderRadius
            }
        }
    })
);

export default locationFieldStyles;
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Person as PersonIcon, Menu as MenuIcon } from '@material-ui/icons';

import LogoSrc from "../../assets/images/Logo180px.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary
        },
        toolbar: {
            padding: theme.spacing(1, 4)
        },
        accountButton: {
            marginLeft: theme.spacing(2),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        logoContainer: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center'
        },
        logo: {
            height: 40,
            "@media (min-width: 768px) and ( min-height: 376px ) ": {
                height:60
            }
        },
        logoText: {
            paddingLeft:  theme.spacing(2),
            fontWeight: 'bold',
            fontVariant: 'small-caps'
        }
    }),
);

export default function NavBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logoContainer}>
                        <img className={classes.logo} src={LogoSrc} alt="Erwin Event Logo"/>
                        <Typography variant="h5" component="div" className={classes.logoText}>Event</Typography>
                    </div>
                    <IconButton edge="end" className={classes.accountButton} color="inherit" aria-label="menu">
                        <PersonIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </div>
    );
}